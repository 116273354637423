export const stateMozard = {
  isLoading: false,
  isDirty: 0,
  isSaving: false,
  currentstep: '11',
  additionalSections: [
    {
      key: 'employments',
      section: 'employments',
      title: 'Employment History',
    },
    {
      key: 'educations',
      section: 'educations',
      title: 'Education',
    },
    {
      key: 'projects',
      section: 'projects',
      title: 'Projects',
    },
    {
      key: 'certifications',
      section: 'certifications',
      title: 'Certifications',
    },
    {
      title: 'Skills',
      key: 'skills',
      section: 'skills',
    },
    {
      key: 'languages',
      section: 'languages',
      title: 'Languages',
    },
    {
      title: 'Links',
      key: 'links',
      section: 'links',
    },
  ],
  design: {
    colorPalette: 'DARK1',
    fontFamily: 'PALATINO',
    density: 'NORMAL',
    templateName: 'ARIES',
    hasLastModifiedDate: false,
    hasParagraphIndendation: false,
    paragraphTextAlignment: 'justify',
  },
  resume: {
    basics: {
      firstName: 'Wolfgang',
      lastName: 'Mozart',
      jobTitle: 'Composer and Musician',
      isOpen: false,
      nationality: 'Austrian',
      dateOfBirth: '27-01-1756',
    },
    contacts: {
      email: 'mozart.wolfgang@jobsboard.io',
      phone: '(01) 23456789',
      country: 'Austria',
      city: 'Vienna',
      address: '',
      postCode: '',
    },
    summary: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Renowned for iconic compositions including "Eine kleine Nachtmusik", "The Marriage of Figaro", and "Symphony No. 40 in G minor", which have significantly influenced classical music.',
          },
        ],
      },
    ],
    skills: {
      title: 'Skills',
      items: [
        {
          key: 'snE9KTF4cyS9HQgW',
          name: 'Composition',
          level: 5,
        },
        {
          key: 'DWWBtbjNsJJNjMm7',
          name: 'Piano Performance',
          level: 5,
        },
        {
          key: 'U1kkde00PfVZuUkj',
          name: 'Opera Composition',
          level: 4,
        },
        {
          key: 'DwfN474ye8PXAkyn',
          name: 'Music Theory',
          level: 4,
        },
        {
          key: 'Tml44dXYxLnYEYQa',
          name: 'Conducting',
          level: 3,
        },
      ],
    },
    links: {
      title: 'Links',
      items: [
        {
          key: 'dOaeniPPk48OHQO5',
          text: 'LinkedIn',
          type: 'linkedin',
          url: 'https://www.linkedin.com/in/willy-wonka-241a65170/',
        },
      ],
    },
    employments: {
      title: 'Employment History',
      section: 'employments',
      items: [
        {
          key: 'employments',
          company: 'Freelance',
          position: 'Composer and Musician',
          location: 'Vienna',
          startDate: '1773',
          endDate: '1791',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Composed numerous symphonies, operas, and chamber music pieces for diverse patrons and commissions across Europe including a prolific catalogue of symphonic works, ranging from the grandeur of symphonies such as Symphony No. 40 in G minor to the elegance of Symphony No. 41 in C major, "Jupiter."',
                },
              ],
            },
          ],
        },
        {
          key: 'ORPcxPHycf6xc74y',
          position: 'Composer',
          company: 'Freelance',
          startDate: '1770',
          endDate: '1773',
          location: 'Vienna',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Composed numerous symphonies, operas, and chamber music pieces for diverse patrons and commissions across Europe.',
                },
              ],
            },
            {
              type: 'bulleted-list',
              children: [
                {
                  type: 'list-item',
                  children: [
                    {
                      text: 'Demonstrated versatility by creating a wide range of musical works, including symphonies, operas, and chamber music pieces.',
                    },
                  ],
                },
                {
                  type: 'list-item',
                  children: [
                    {
                      text: 'Successfully fulfilled commissions for various patrons, showcasing adaptability to different musical styles and preferences.',
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    educations: {
      title: 'Education',
      section: 'educations',
      items: [
        {
          key: 'educations',
          institution: 'Salzburg Cathedral Music School',
          studyType: 'Salzburg Cathedral Music School',
          location: 'Austria, Salzburg',
          startDate: '1761',
          endDate: '1766',
          area: 'Music Theory',
          score: 'Distinction',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Studied under the tutelage of renowned composers and music theorists, honing skills in music composition and theory.',
                },
              ],
            },
          ],
        },
        {
          key: 'iQUw2YG2G4wtOvQ3',
          institution: 'University of Padua',
          studyType: 'Doctor of Music',
          startDate: '1770',
          endDate: '1773',
          location: 'Italy, Padua',
          area: 'Music Composition',
          score: 'Distinction',
        },
      ],
    },
    projects: {
      title: 'Projects',
      section: 'projects',
      items: [
        {
          key: 'projects',
          name: 'The Marriage of Figaro',
          date: '1786',
          url: 'https://example.com',
          keywords: [],
        },
        {
          key: 'AuTNMyLk7WgM7Pgx',
          name: 'Symphony No. 40 in G minor',
          date: '1788',
        },
      ],
    },
    certifications: {
      title: 'Certifications',
      section: 'certifications',
      items: [
        {
          key: 'certifications',
          title: 'Master Composer',
          issuer: 'Royal Academy of Music',
          date: '1770',
          url: '',
        },
      ],
    },
    languages: {
      title: 'Languages',
      section: 'languages',
      items: [
        {
          key: 'languages',
          language: 'German',
          proficiency: 5,
        },
        {
          key: 'amWTf56srWlgiCrO',
          language: 'French',
          proficiency: 4,
        },
        {
          key: 'fCExwoFsy7TRPE32',
          language: 'Italian',
          proficiency: 3,
        },
      ],
    },
  },
};
