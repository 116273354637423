export const stateBeethoven = {
  isLoading: false,
  isDirty: 0,
  isSaving: false,
  currentstep: '1',
  additionalSections: [
    {
      key: 'employments',
      section: 'employments',
      title: 'Employment History',
    },
    {
      key: 'skills',
      section: 'skills',
      title: 'Skills',
    },
    {
      key: 'certifications',
      section: 'certifications',
      title: 'Certifications',
    },
    {
      key: 'projects',
      section: 'projects',
      title: 'Projects',
    },
    {
      key: 'educations',
      section: 'educations',
      title: 'Education',
    },
    {
      key: 'links',
      section: 'links',
      title: 'Links',
    },
    {
      key: 'languages',
      section: 'languages',
      title: 'Languages',
    },
  ],
  design: {
    colorPalette: 'BACKGROUND1',
    fontFamily: 'HELVETICA',
    density: 'NORMAL',
    templateName: 'ARIES',
    hasLastModifiedDate: false,
    hasParagraphIndendation: false,
    paragraphTextAlignment: 'justify',
  },
  resume: {
    basics: {
      firstName: 'Ludwig van',
      lastName: 'Beethoven',
      jobTitle: 'Composer and Pianist',
      nationality: 'German',
      dateOfBirth: '1770-12-17',
      placeOfBirth: 'Bonn, Electorate of Cologne, Holy Roman Empire',
      workRightUK: false,
      workRightEU: false,
      isOpen: true,
      availability: 'Not Applicable',
    },
    contacts: {
      email: 'beethoven.ludwig@example.com',
      phone: '(+49) 123 456 789',
      country: 'Germany',
      city: 'Vienna',
      postCode: '1010',
      address: '1 Beethoven Lane',
    },
    summary: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Renowned composer and pianist celebrated for his contributions to classical music. Revered for his mastery of musical composition despite facing challenges such as hearing loss.',
            bold: true,
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Known for iconic works such as "Symphony No. 9 in D minor", "Moonlight Sonata", and "Für Elise", which continue to inspire and influence musicians worldwide.',
          },
        ],
      },
    ],
    employments: {
      title: 'Employment History',
      section: 'employments',
      items: [
        {
          key: 'Q4Sx9qyU0lWbytfE',
          company: 'Freelance Composer and Pianist',
          position: 'Composer and Pianist',
          startDate: '1792',
          endDate: '1827',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Composed numerous symphonies, sonatas, and chamber music pieces, while establishing himself as a virtuoso pianist and conductor.',
                  italic: true,
                },
              ],
            },
          ],
          location: 'Vienna',
        },
      ],
    },
    skills: {
      title: 'Skills',
      section: 'skills',
      items: [
        {
          name: 'Composition',
          level: 5,
          key: 'MHyFl2pJ8nVbzPZw',
          highlights: [],
        },
        {
          name: 'Piano Performance',
          level: 5,
          key: 'zKnOvX5xhAlVywzN',
          highlights: [],
        },
        {
          name: 'Orchestration',
          level: 4,
          key: 'qP3ZnVQlnxlW9wEK',
          highlights: [],
        },
        {
          name: 'Conducting',
          level: 4,
          key: 'rXyPwElZ0wngloKW',
          highlights: [],
        },
        {
          key: 'a0PrEQjEzlgndvl5',
          name: 'Improvisation',
          level: 4,
        },
        {
          key: 'xWPyzN5lWaHZl98o',
          name: 'Music Theory',
          level: 5,
        },
      ],
    },
    certifications: {
      title: 'Certifications',
      section: 'certifications',
      items: [
        {
          title: 'Master Composer',
          issuer: 'Imperial Court of the Holy Roman Empire',
          date: '1792',
          url: '',
          key: 'gPOqRJxL0wMaNvXJ',
          highlights: [],
        },
        {
          key: 'zOnNlM9LdWEpYl30',
          title: 'Piano Virtuoso',
          issuer: 'Vienna Conservatory',
          date: '1795',
        },
      ],
    },
    projects: {
      title: 'Projects',
      section: 'projects',
      items: [
        {
          key: 'SpwZyFBlzP9lVywq',
          name: 'Symphony No. 9 in D minor',
          date: '1824',
          url: '',
        },
        {
          key: 'kRwENb4xhAqPqEJ9',
          name: 'Moonlight Sonata',
          date: '1801',
          url: '',
        },
      ],
    },
    educations: {
      title: 'Education',
      section: 'educations',
      items: [
        {
          institution: 'University of Music and Performing Arts Vienna',
          studyType: 'Composition and Piano',
          location: 'Austria, Vienna',
          startDate: '1787',
          endDate: '1792',
          area: 'Music',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Studied under renowned composers of the time, including Joseph Haydn and Johann Georg Albrechtsberger.',
                },
              ],
            },
          ],
          key: 'L9EzKwEhPgMqPlXQ',
          score: 'Distinction',
        },
      ],
    },
    links: {
      title: 'Links',
      section: 'links',
      items: [
        {
          key: 'wHyF2pE9MzLl2qKJ',
          url: 'https://beethoven.com',
          type: 'Website',
          text: 'Personal Website',
        },
      ],
    },
    languages: {
      title: 'Languages',
      section: 'languages',
      items: [
        {
          key: 'eMzPw9XaJwEnNlOr',
          language: 'German',
          proficiency: 5,
        },
      ],
    },
  },
};
