export const stateChopin = {
  isLoading: false,
  isDirty: 0,
  isSaving: false,
  currentstep: '1',
  additionalSections: [
    {
      key: 'employments',
      section: 'employments',
      title: 'Employment History',
    },
    {
      key: 'skills',
      section: 'skills',
      title: 'Skills',
    },
    {
      key: 'certifications',
      section: 'certifications',
      title: 'Certifications',
    },
    {
      key: 'projects',
      section: 'projects',
      title: 'Projects',
    },
    {
      key: 'educations',
      section: 'educations',
      title: 'Education',
    },
    {
      key: 'links',
      section: 'links',
      title: 'Links',
    },
    {
      key: 'languages',
      section: 'languages',
      title: 'Languages',
    },
  ],
  design: {
    colorPalette: 'DEFAULT',
    fontFamily: 'COURIER',
    density: 'NORMAL',
    templateName: 'TAURUS',
    hasLastModifiedDate: false,
    hasParagraphIndendation: true,
    paragraphTextAlignment: 'left',
  },
  resume: {
    basics: {
      firstName: 'Frédéric',
      lastName: 'Chopin',
      jobTitle: 'Composer and Pianist',
      nationality: 'Polish',
      dateOfBirth: '1810-03-01',
      placeOfBirth: 'Żelazowa Wola, Duchy of Warsaw, Poland',
      workRightUK: false,
      workRightEU: false,
      isOpen: true,
      availability: 'Not Applicable',
    },
    contacts: {
      email: 'chopin.frederic@example.com',
      phone: '(+48) 123 456 789',
      country: 'Poland',
      city: 'Paris',
      postCode: '75000',
      address: '1 Chopin Street',
    },
    summary: [
      {
        type: 'paragraph',
        children: [
          {
            text: 'Renowned composer and pianist known for his innovative compositions and virtuosic piano performances. His works, characterized by their emotional depth and technical brilliance, continue to inspire musicians and audiences worldwide.',
            bold: true,
          },
        ],
      },
      {
        type: 'paragraph',
        children: [
          {
            text: 'Famous compositions include "Nocturnes", "Polonaises", and "Études", which showcase his mastery of the piano and his unique musical style.',
          },
        ],
      },
    ],
    employments: {
      title: 'Employment History',
      section: 'employments',
      items: [
        {
          key: 'ExZnBxJElqPW0aGz',
          company: 'Freelance Composer and Pianist',
          position: 'Composer and Pianist',
          startDate: '1829',
          endDate: '1849',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Composed numerous piano pieces, including nocturnes, preludes, and waltzes, while performing in concerts across Europe.',
                  italic: true,
                },
              ],
            },
          ],
          location: 'Paris',
        },
      ],
    },
    skills: {
      title: 'Skills',
      section: 'skills',
      items: [
        {
          name: 'Piano Performance',
          level: 5,
          key: 'OzVwJBlzgQk5fSzr',
          highlights: [],
        },
        {
          name: 'Composition',
          level: 5,
          key: 'BxPmL0xJzGNqfWAy',
          highlights: [],
        },
        {
          name: 'Improvisation',
          level: 4,
          key: 'OlPLzEwAr0yXhL9v',
          highlights: [],
        },
        {
          name: 'Music Theory',
          level: 5,
          key: 'JxSrwzBP0QyLfvMA',
          highlights: [],
        },
        {
          key: 'TzVjxAyBrOl0PmLk',
          name: 'Orchestration',
          level: 4,
        },
      ],
    },
    certifications: {
      title: 'Certifications',
      section: 'certifications',
      items: [
        {
          title: 'Master Composer',
          issuer: 'Paris Conservatory',
          date: '1834',
          url: '',
          key: 'GqPwJzAsOxVrBl0y',
          highlights: [],
        },
        {
          key: 'JzVxXwMBrOL0kPgA',
          title: 'Virtuoso Pianist',
          issuer: 'Warsaw Music Academy',
          date: '1827',
        },
      ],
    },
    projects: {
      title: 'Projects',
      section: 'projects',
      items: [
        {
          key: 'zVxXwMBPrOL0kPgT',
          name: 'Nocturnes',
          date: '1830-1849',
          url: '',
        },
        {
          key: 'OzVwJBzPqk5fSzr',
          name: 'Preludes',
          date: '1837-1839',
          url: '',
        },
      ],
    },
    educations: {
      title: 'Education',
      section: 'educations',
      items: [
        {
          institution: 'Warsaw Music Academy',
          studyType: 'Composition and Piano Performance',
          location: 'Poland, Warsaw',
          startDate: '1823',
          endDate: '1829',
          area: 'Music',
          highlights: [
            {
              type: 'paragraph',
              children: [
                {
                  text: 'Received formal training in composition and piano performance, studying under renowned composers and pianists.',
                },
              ],
            },
          ],
          key: 'zVwMBzgQk5fSzOl',
          score: 'Distinction',
        },
      ],
    },
    links: {
      title: 'Links',
      section: 'links',
      items: [
        {
          key: 'JzAwkPgAQzAsOxVr',
          url: 'https://chopin.com',
          type: 'Website',
          text: 'Personal Website',
        },
      ],
    },
    languages: {
      title: 'Languages',
      section: 'languages',
      items: [
        {
          key: 'PmLkVjyArOzEwBxQ',
          language: 'Polish',
          proficiency: 5,
        },
        {
          key: 'AsOxVrBl0kPgAjMz',
          language: 'French',
          proficiency: 4,
        },
      ],
    },
  },
};
